import classNames from 'shared/lib/class-names'

import cls from './About.module.scss'

export default function About() {
  return (
    <section className={classNames('flex__col_center', cls.page)} id="about">
      <div className={classNames(cls.page__container, 'container__desktop')}>
        <div className={cls.left_block}>
          <div className={cls.left_block__content}>
            <h2 className={cls.title}>
              С Freedom зарабатывают
              <span>412 547 наших клиентов</span>
            </h2>
          </div>
        </div>
        <div className={cls.right_block}>
          <div className={cls.card}>
            <h3 className={cls.card__title}>О Freedom Finance</h3>
            <p className={cls.card__desc}>
              <span>«Фридом Финанс»</span> - инвестиционная компания международного уровня, представленная в 13 странах. На наших идеях зарабатывает более 400 тысяч инвесторов по всему миру!
              Компания входит в международную инвестиционную группу Freedom Holding Corp. На рынке с 2008 года.
              Акции Freedom Holding Corp. размещены на Nasdaq Capital Market под тикером FRHC. Деятельность холдинга регулируется Комиссией по ценным бумагам и биржам (SEC, США).
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
